body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: rgb(19, 19, 19);
	color: silver;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* PERSONAL CODE BEGINS HERE */
.title {
	text-align: center;
}
ul {
	list-style: none;
}
.Top-image {
	width: 100%;
	max-height: 69vh;
}
.profileContainer {
	/* margin: 0 auto; */
	text-align: center;
}
/* Porfolio CSS */
.projects-container {
	margin: 0 auto;
}
.proj-sub {
	margin: 0 auto;
	max-width: 1150px;
}
.proj-sub > a {
	margin: 0 auto;
	width: 100%;
}
.proj-sub img {
	width: 100%;
}
.profilePic {
	border: 2px solid white;
	object-fit: cover;
	object-position: -0.5px -12px;
}
.skills {
	margin-bottom: 40px;
}
.skillsIcon {
	font-size: 80px;
}
.BottomIcon {
	font-size: 45px;
}
.cancelCSS {
	font-size: medium;
	/* display: block; */
	margin-left: 35%;
	margin-top: -39%;
	/* padding-bottom: -100px; */
}
/* .navbar{
  position: absolute;
} */

.bottom {
	margin: 10px;
}
.b-el {
	padding: 15px;
}
.bottom a {
	color: white;
}

/* .master {
	position: relative;
} */

/* .top {
	position: fixed;
	width: 100%;
} */
.content {
	padding-top: 85px;
}

.hidethis {
	/* visibility: hidden; */
	display: none;
}
